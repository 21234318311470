import { adminState, AdminState } from './admin/state';
import { flowState, FlowState } from './flow/state';
import { loginState, LoginState } from './login/state';
import { overviewState, OverviewState } from './overview/state';
import { rootState, RootState } from './root/state';

export type State = FlowState & OverviewState & RootState & LoginState & AdminState;

export const state: State = { ... flowState, ...overviewState, ...rootState, ...loginState, ...adminState };

// Check for duplicates, to preserve my own sanity in the future
(() => {
    const arr = [
        ...Object.keys(flowState),
        ...Object.keys(overviewState),
        ...Object.keys(rootState),
        ...Object.keys(loginState),
        ...Object.keys(adminState)
    ];
    if (new Set(arr).size !== arr.length) throw new Error('Duplicate states found in store');
})()
