export interface AlertOptions {
    type?: 'default' | 'danger' | 'positive',
    icon?: string,
    title?: string,
    subtitle?: string,
    text?: string,
    cancel?: string,
    confirm?: string,
    confirm2?: string,
    onConfirm?: () => void,
    onConfirm2?: () => void,
    onCancel?: () => void
}

export interface RootState {
    loading: number;
    alert: AlertOptions | null;
    notificationInstance: any;
}

export const rootState: RootState = {
    loading: 0,
    alert: null,
    notificationInstance: null
}
