import { MutationTree } from 'vuex'
import { AdminState } from './state'

export interface AdminMutations {
    ['setAdminStatus'](state: AdminState, status: Record<string, any>): void;
    ['setHistory'](state: AdminState, history: Array<Record<string, any>>): void;
    ['setRecentlyChanged'](state: AdminState, recentlyChanged: Array<Record<string, any>>): void;
}
export const adminMutations: MutationTree<AdminState> & AdminMutations = {
    ['setAdminStatus'](state: AdminState, status: Record<string, any>): void {
        state.adminStatus = status;
    },
    ['setHistory'](state: AdminState, history: Array<Record<string, any>>): void {
        state.history = history;
    },
    ['setRecentlyChanged'](state: AdminState, recentlyChanged: Array<Record<string, any>>): void {
        state.recentlyChanged = recentlyChanged;
    }
}
