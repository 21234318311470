import { ActionTree } from 'vuex';
import { State } from '../state';
import { ActionAugments, instance } from '../actions';

export interface LoginActions {
    ['logout'](context: ActionAugments): Promise<void>;
    ['checkLogin'](context: ActionAugments): Promise<void>;
    ['setOrganization'](context: ActionAugments, id: string): Promise<void>;
    ['appPortalLogin'](context: ActionAugments, code: string): Promise<any>;
}

export const loginActions: ActionTree<State, State> & LoginActions = {
    ['logout']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: 'auth/logout'
            }).then(() => {
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                window.location.href = '/';
                commit('setLoading', false);
            });
        });
    },
    ['checkLogin']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            instance({
                method: 'GET',
                url: 'auth/check'
            }).then((res) => {
                commit('setUser', res.data)
                resolve();
            }).catch((e) => {
                reject(e);
            });
        });
    },
    ['setOrganization']({ commit }, id: string): Promise<void>{
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: 'organizations/set',
                data: {
                    organization: id
                }
            }).then(() => {
                resolve();
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Organisatie selecteren mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['appPortalLogin']({ commit }, code: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: 'auth/appPortalLogin',
                data: {
                    code
                }
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'AppPortal login mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    }
}