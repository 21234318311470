import { MutationTree } from 'vuex'
import { LoginState } from './state'

export interface LoginMutations {
    ['setUser'](state: LoginState, user: Record<string, any>): void;
}
export const loginMutations: MutationTree<LoginState> & LoginMutations = {
    ['setUser'](state: LoginState, user: Record<string, any>): void {
        state.user = user;
    },
}