export interface AdminState {
    adminStatus: Record<string, any> | null;
    history: Array<Record<string, any>> | null;
    recentlyChanged: Record<string, any>[] | null;
}

export const adminState: AdminState = {
    adminStatus: null,
    history: null,
    recentlyChanged: null
}
