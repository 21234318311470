import { ActionTree } from 'vuex';
import { State } from '../state';
import { ActionAugments, instance } from '../actions';

export interface AdminActions {
    ['getAdminStatus'](context: ActionAugments, options: Record<string, any>): Promise<Record<string, any>>;
    ['searchFlows'](context: ActionAugments, query: string): Promise<Array<Record<string, any>>>;
    ['getHistory'](context: ActionAugments, options: Record<string, any>): Promise<Array<Record<string, any>>>;
    ['unlimited'](context: ActionAugments): Promise<Array<Record<string, any>>>;
    ['queueUnlimited'](context: ActionAugments, flowId: string): Promise<void>;
    ['saveOrganization'](context: ActionAugments, organization: Record<string, any>): Promise<void>;
    ['saveComment'](context: ActionAugments, data: Record<string, any>): Promise<void>;
    ['getRecentlyChanged'](context: ActionAugments, options: Record<string, any>): Promise<Record<string, any>>;
}

export const adminActions: ActionTree<State, State> & AdminActions = {
    ['getAdminStatus']({ commit }, options: Record<string, any>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            if (!options.hideLoader) commit('setLoading', true);

            let params = '';
            if (options.uuid) params = '/' + options.uuid;
            instance({
                method: 'GET',
                url: 'admin/status' + params
            }).then((res) => {
                commit('setAdminStatus', res.data);
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                if (!options.hideLoader) commit('setLoading', false);
            });
        });
    },
    ['searchFlows']({ commit }, query: string): Promise<Array<Record<string, any>>> {
        return new Promise<Array<Record<string, any>>>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: `admin/searchFlows?query=${query}`
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getHistory']({ commit }, options: Record<string, any>): Promise<Array<Record<string, any>>> {
        return new Promise<Array<Record<string, any>>>((resolve, reject) => {
            if (!options.hideLoader) commit('setLoading', true);

            let params = '';
            if (options.uuid) params = '/' + options.uuid;
            instance({
                method: 'GET',
                url: 'admin/history' + params
            }).then((res) => {
                commit('setHistory', res.data);
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                if (!options.hideLoader) commit('setLoading', false);
            });
        });
    },
    ['unlimited'](): Promise<Array<Record<string, any>>> {
        return new Promise<Array<Record<string, any>>>((resolve, reject) => {
            instance({
                method: 'GET',
                url: 'admin/unlimited'
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    ['queueUnlimited']({ commit }, flowId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `admin/queueUnlimited`,
                data: {
                    flow: flowId
                }
            }).then(() => {
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['saveOrganization']({ commit }, organization: Record<string, any>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `admin/saveOrganization`,
                data: organization
            }).then(() => {
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['saveComment']({ commit }, data: Record<string, any>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `admin/comment`,
                data
            }).then(() => {
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getRecentlyChanged']({ commit }, options: Record<string, any>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            if (!options.hideLoader) commit('setLoading', true);

            instance({
                method: 'GET',
                url: 'admin/recentlyChanged'
            }).then((res) => {
                commit('setRecentlyChanged', res.data);
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                if (!options.hideLoader) commit('setLoading', false);
            });
        });
    },
}