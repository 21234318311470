import _ from 'lodash';
import { FlowJSON } from '@/engine';
import { ActionTree } from 'vuex';
import { State } from '../state';
import { ActionAugments, instance } from '../actions';

export interface FlowActions {
    ['getVertexMetadata'](context: ActionAugments): Promise<void>;
    ['getFlowJSON'](context: ActionAugments, uuid: string): Promise<FlowJSON>;
    ['createFlow'](context: ActionAugments, flowJSON: FlowJSON): Promise<string>;
    ['updateFlow'](context: ActionAugments, obj: Record<string, any>): Promise<void>;
    ['runFlow'](context: ActionAugments): Promise<Record<string, any>>;
    ['testFlow'](context: ActionAugments): Promise<Record<string, any>>;
    ['getAvailableConnectors'](context: ActionAugments, payload: Record<string, string>): Promise<Record<string, any>>;
    ['getConnectorMetadata'](context: ActionAugments, payload: Record<string, string>): Promise<Record<string, any>>;
    ['getLog'](context: ActionAugments, obj: Record<string, any>): Promise<Record<string, any>>;
    ['getVersions'](context: ActionAugments, obj: Record<string, any>): Promise<Record<string, any>>;
    ['getVersion'](context: ActionAugments, obj: Record<string, any>): Promise<FlowJSON>;
    ['restoreVersion'](context: ActionAugments, obj: Record<string, any>): Promise<void>;
    ['uploadFile'](context: ActionAugments, data: Record<string, any>): Promise<string>;
}

export const flowActions: ActionTree<State, State> & FlowActions = {
    ['getVertexMetadata']({ commit }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: 'vertices'
            }).then((res) => {
                const mappedData: Record<string, any> = {};
                for (const vertex of res.data) {
                    mappedData[vertex.vertex] = vertex;
                }
                commit('setVertexMetadata', mappedData);
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getFlowJSON']({ commit }, uuid: string): Promise<FlowJSON> {
        return new Promise<FlowJSON>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: `flows/${uuid}`
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                if (e.response?.status ===  403) {
                    commit('setAlert', {
                        type: 'default',
                        title: 'Geen toegang',
                        text: 'Deze Flow behoort niet tot de ingelogde organisatie.',
                        confirm: 'Terug naar Organisaties',
                        confirm2: 'Terug naar Overzicht',
                        onConfirm: () => {
                            window.location.href = 'https://app.simplexconnect.nl/?screen=organizations';
                        },
                        onConfirm2: () => {
                            window.location.href = `/overview`;
                        }
                    });
                }
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['createFlow']({ commit }, flowJSON: FlowJSON): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: 'flows',
                data: flowJSON
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Aanmaken van de Flow mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['updateFlow']({ state, commit }, { options, reason }: Record<string, any>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (state.currentFlow && state.currentFlow._id) {
                const id = _.cloneDeep(state.currentFlow._id);
                const payload = _.cloneDeep(state.currentFlow.toJSON());
                delete payload._id;
                if (options) payload.options = options;
                if (reason) payload.reason = reason;
                commit('setLoading', true);
                instance({
                    method: 'PUT',
                    url: `flows/${id}`,
                    data: payload
                }).then(() => {
                    commit('notify', {
                        type: 'success',
                        options: {
                            title: 'Flow aangepast',
                            meta:  'Update Success'
                        }
                    });
                    resolve();
                }).catch((e) => {
                    commit('notify', {
                        type: 'error',
                        options: {
                            title: 'Aanpassen van de Flow mislukt',
                            meta:  e.response?.data || e.message || 'Internal Server Error'
                        }
                    });
                    reject(e);
                }).finally(() => {
                    commit('setLoading', false);
                });
            } else reject();
        });
    },
    ['runFlow']({ state, commit }): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            if (state.currentFlow) {
                commit('setLoading', true);
                instance({
                    method: 'POST',
                    url: 'run',
                    data: state.currentFlow
                }).then((res) => {
                    resolve(res.data);
                }).catch((e) => {
                    commit('notify', {
                        type: 'error',
                        options: {
                            title: 'Uitvoeren van de Flow mislukt',
                            meta:  e.response?.data || e.message || 'Internal Server Error'
                        }
                    });
                    reject(e);
                }).finally(() => {
                    commit('setLoading', false);
                });
            } else reject();
        });
    },
    ['testFlow']({ state, commit }): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            if (state.currentFlow) {
                commit('setLoading', true);
                instance({
                    method: 'POST',
                    url: 'test',
                    data: state.currentFlow
                }).then((res) => {
                    resolve(res.data);
                }).catch((e) => {
                    commit('notify', {
                        type: 'error',
                        options: {
                            title: 'Testen van de Flow mislukt',
                            meta:  e.response?.data || e.message || 'Internal Server Error'
                        }
                    });
                    reject(e);
                }).finally(() => {
                    commit('setLoading', false);
                });
            } else reject();
        });
    },
    ['getAvailableConnectors']({ commit }, payload: Record<string, any>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: 'afas/metainfo',
                data: {
                    environment: payload.environment,
                    token: payload.token
                }
            }).then((res) => {
                commit('setAvailableConnectors', {
                    environment: payload.environment,
                    token: payload.token,
                    availableConnectors: res.data
                })
                resolve(res.data);
            }).catch((e) => {
                commit('notify', {
                    type: 'warning',
                    options: {
                        title: 'Ophalen van Connectoren mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getConnectorMetadata']({ commit }, payload: Record<string, any>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            commit('setLoading', true);
            let url;
            if (payload.type === 'UPDATE') url = `afas/metainfo/update/${payload.connectorId}`;
            else if (payload.type === 'GET') url = `afas/metainfo/get/${payload.connectorId}`;
            else return reject();
            instance({
                method: 'POST',
                url: url,
                data: {
                    environment: payload.environment,
                    token: payload.token
                }
            }).then((res) => {
                commit('setConnectorMetadata', {
                    environment: payload.environment,
                    token: payload.token,
                    id: payload.connectorId,
                    connectorMetadata: res.data
                })
                resolve(res.data);
            }).catch((e) => {
                commit('notify', {
                    type: 'warning',
                    options: {
                        title: 'Ophalen van Connectoren mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getLog']({ commit }, obj: Record<string, any>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: `flows/${obj.flow}/logs/${obj.logId}`
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Ophalen van logs mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getVersions']({ commit }, obj: Record<string, any>): Promise<Record<string, any>> {
        return new Promise<Record<string, any>>((resolve, reject) => {
            commit('setLoading', true);
            let url = `flows/${obj.flow}/versions`;
            if (obj.skip){
                url += `?skip=${obj.skip}`;
            }
            instance({
                method: 'GET',
                url: url
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['getVersion']({ commit }, obj: Record<string, any>): Promise<FlowJSON>  {
        return new Promise<FlowJSON> ((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'GET',
                url: `flows/${obj.flow}/versions/${obj.versionId}`
            }).then((res) => {
                resolve(res.data);
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['restoreVersion']({ commit }, obj: Record<string, any>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: `flows/${obj.flow}/versions/${obj.versionId}/restore`
            }).then(() => {
                resolve();
            }).catch((e) => {
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
    ['uploadFile']({ commit }, data: Record<string, any>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            commit('setLoading', true);
            instance({
                method: 'POST',
                url: 'file/upload',
                data
            }).then((res) => {
                commit('notify', {
                    type: 'success',
                    options: {
                        title: 'Bestand geüpload',
                        meta: 'File Uploaded'
                    }
                });
                resolve(res.data);
            }).catch((e) => {
                commit('notify', {
                    type: 'error',
                    options: {
                        title: 'Uploaden van bestand is mislukt',
                        meta:  e.response?.data || e.message || 'Internal Server Error'
                    }
                });
                reject(e);
            }).finally(() => {
                commit('setLoading', false);
            });
        });
    },
}