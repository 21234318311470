import axios from 'axios';
import { ActionContext } from 'vuex'
import { Mutations } from './mutations'
import { State } from './state';
import { flowActions, FlowActions } from './flow/actions';
import { loginActions, LoginActions } from './login/actions';
import { overviewActions, OverviewActions } from './overview/actions';
import { adminActions, AdminActions } from './admin/actions';

let baseUrl;
if (process.env.VUE_APP_ENV === 'production') {
    baseUrl = 'https://backend.flow.simplexconnect.nl/api/';
} else if (process.env.VUE_APP_ENV === 'development'){
    baseUrl = 'https://flowbackend-dev.simplexconnect.nl/api/';
} else if (process.env.VUE_APP_ENV === 'local') {
    baseUrl = 'http://localhost:3000/api/';
}

export const instance = axios.create({
    withCredentials: true,
    baseURL: baseUrl
});

export type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
}

export type Actions = FlowActions & LoginActions & OverviewActions & AdminActions;
export const actions = { ...flowActions, ...loginActions, ...overviewActions, ...adminActions };

// Check for duplicates, to preserve my own sanity in the future
(() => {
    const arr = [
        ...Object.keys(flowActions),
        ...Object.keys(loginActions),
        ...Object.keys(overviewActions),
        ...Object.keys(adminActions)
    ];
    if (new Set(arr).size !== arr.length) throw new Error('Duplicate actions found in store');
})()
