import { flowMutations, FlowMutations } from "./flow/mutations"
import { overviewMutations, OverviewMutations } from './overview/mutations';
import { rootMutations, RootMutations } from './root/mutations';
import { loginMutations, LoginMutations } from "./login/mutations";
import { adminMutations, AdminMutations } from "./admin/mutations";

export type Mutations = FlowMutations & OverviewMutations & RootMutations & LoginMutations & AdminMutations;

export const mutations = { ...flowMutations, ...overviewMutations, ...rootMutations, ...loginMutations, ...adminMutations };

// Check for duplicates, to preserve my own sanity in the future
(() => {
    const arr = [
        ...Object.keys(flowMutations),
        ...Object.keys(overviewMutations),
        ...Object.keys(rootMutations),
        ...Object.keys(loginMutations),
        ...Object.keys(adminMutations)
    ];
    if (new Set(arr).size !== arr.length) throw new Error('Duplicate mutations found in store');
})()
