import { Flow, Vertex } from '@/engine';
import { VertexMetadata } from '@/engine/vertex';

export interface FlowState {
    currentFlow: Flow | null;
    selectedVertex: Vertex | null;
    vertexMetadata: Record<string, VertexMetadata> | null;
    connectorMetadata: Record<string, any> | null;
    availableConnectors: Record<string, any> | null;
    report: Record<string, any> | null;
    allAvailableFields: Array<Record<string, string>> | null;
    getConnectorFields: Array<Record<string, string>> | null;
}

export const flowState: FlowState = {
    currentFlow: null,
    selectedVertex: null,
    vertexMetadata: null,
    connectorMetadata: null,
    availableConnectors: null,
    report: null,
    allAvailableFields: null,
    getConnectorFields: null
}
