import { Flow, Vertex } from '@/engine';
import { VertexMetadata } from '@/engine/vertex';
import { MutationTree } from 'vuex'
import { FlowState } from './state'

export interface FlowMutations {
    ['setCurrentFlow'](state: FlowState, flow: Flow): void;
    ['setSelectedVertex'](state: FlowState, vertex: Vertex | null): void;
    ['setVertexMetadata'](state: FlowState, vertexMetadata: Record<string, VertexMetadata>): void;
    ['setConnectorMetadata'](state: FlowState, data: Record<string, any>): void;
    ['setAvailableConnectors'](state: FlowState, data: Record<string, any>): void;
}
export const flowMutations: MutationTree<FlowState> & FlowMutations = {
    ['setCurrentFlow'](state: FlowState, flow: Flow): void {
        state.currentFlow = flow;
    },
    ['setSelectedVertex'](state: FlowState, vertex: Vertex): void {
        state.selectedVertex = vertex;
    },
    ['setVertexMetadata'](state: FlowState, vertexMetadata: Record<string, VertexMetadata>): void {
        state.vertexMetadata = vertexMetadata;
    },
    ['setConnectorMetadata'](state: FlowState, data: Record<string, any>): void {
        if (!state.connectorMetadata) {
            state.connectorMetadata = {};
        } 
        if (!state.connectorMetadata[data.environment]) {
            state.connectorMetadata[data.environment] = {};
        }
        if (!state.connectorMetadata[data.environment][data.token]) {
            state.connectorMetadata[data.environment][data.token] = {};
        }
        state.connectorMetadata[data.environment][data.token][data.id] = data.connectorMetadata;
    },
    ['setAvailableConnectors'](state: FlowState, data: Record<string, any>): void {
        if (!state.availableConnectors) {
            state.availableConnectors = {};
        } 
        if (!state.availableConnectors[data.environment]) {
            state.availableConnectors[data.environment] = {};
        }
        if (!state.availableConnectors[data.environment][data.token]) {
            state.availableConnectors[data.environment][data.token] = {};
        }
        state.availableConnectors[data.environment][data.token]['updateConnectors'] = data.availableConnectors.updateConnectors;
        state.availableConnectors[data.environment][data.token]['getConnectors'] = data.availableConnectors.getConnectors;
    }
}
