import { MutationTree } from 'vuex';
import { AlertOptions, RootState } from './state';
import { NotificationType } from 'naive-ui';
import _ from 'lodash';
import { VNodeChild } from 'vue';

interface Notification {
    type: NotificationType;
    options: {
        title: string,
        description?: string | (() => VNodeChild),
        content?: string,
        meta?: string,
        duration?: number,
        keepAliveOnHover?: boolean
        closable?: boolean
    }
}

export interface RootMutations {
    ['setLoading'](state: RootState, loading: boolean): void;
    ['setAlert'](state: RootState, options: AlertOptions | null): void;
    ['setNotificationInstance'](state: RootState, notification: any): void;
    ['notify'](state: RootState, notification: Notification): void;
}

export const rootMutations: MutationTree<RootState> & RootMutations = {
    ['setLoading'](state: RootState, loading: boolean): void {
        if (loading === true){
            state.loading++;
        } else if (loading === false){
            state.loading--;
        }
    },
    ['setAlert'](state: RootState, options: AlertOptions | null): void {
        state.alert = options;
    },
    ['setNotificationInstance'](state: RootState, notification: any): void {
        state.notificationInstance = notification;
    },
    ['notify'](state: RootState, notification: Notification): void {
        if (notification.options.content === undefined) notification.options.content = hre(notification.options.meta);
        if (notification.options.duration === undefined) notification.options.duration = 7500;
        state.notificationInstance[notification.type](notification.options);
    }
}

function hre(meta?: string): string | undefined {
    const mapping: Record<string, string> = {
        'Runtime Error': 'Errors vind je door op de actie met error te klikken, en vervolgens op het tabblad \'Output\'.',
        'Runtime Warning': 'Je Flow duurde bijna langer dan 1 minuut, en is mogelijk vervroegd onderbroken.',
        'Run Success': 'De resultaten per actie vind je door op de actie te klikken, onder het tabblad \'Output\'.',
        'Flow Title Missing': 'Onder \'Opties\' kun je de naam van de Flow invullen.',
        'Config Mismatch': 'Sommige velden zijn automatisch verwijderd, omdat ze niet (meer) gebruikt worden in de configuratie. Controleer de wijzigingen handmatig en sla ze op.',
        'Update Success': 'Succesvol geupdate.',
        'Credential Invalid': 'De ingevoerde gegevens zijn ongeldig.',
        'Credential Edited': 'Succesvol geupdate.',
        'Schedule Saved': 'De Flow is succesvol ingepland.',
        'Schedule Deleted': 'De Flow is succesvol uit de planning gehaald.',
        '*: ValidationError: "config.*" is required': 'Je hebt een actie toegevoegd zonder de configuratie in te vullen. Zorg ervoor dat alle velden gevuld zijn.',
        'Request failed with status code *': 'Er is een onverwachte fout opgetreden.',
        'Flow Deleted': 'De Flow is succesvol verwijderd.',
        'This action is not allowed by your current subscription plan. Please contact Simplex Connect for information regarding upgrading.': 'Je huidige abonnement staat het niet toe om een nieuwe Flow te plannen. Controleer hoeveel punten er nog over zijn. Neem contact op met connect@simplex.nl voor meer punten!',
        '"environment" with value "*" fails to match the required pattern: /^[OAT]{1}[0-9]{5}[A-Z]{2}$/': 'De AFAS omgeving is ongeldig. Deze begint meestal met een O en eindigt op 2 letters. Bijvoorbeeld O12345AA of T12345AA.',
        'GetConnectorAdvanced*: ValidationError: "config.*" must be greater than or equal to 0': 'Het is niet toegestaan om skip/take -1 te gebruiken.  Kies een waarde tussen de 1 en 10000.',
        '*: ValidationError: "config.outputField" is not allowed to be empty': 'Het veld "Output Veldnaam" is niet gevuld in een van de acties.',
        '*: ValidationError: "config.schema" is required': 'Het veld "Schema" is niet gevuld in een van de acties.',
        'GetConnectorAdvanced*: ValidationError: "config.*" must be less than or equal to 10000': 'Het is niet toegestaan om skip/take -1 te gebruiken.  Kies een waarde tussen de 1 en 10000.',
        'MultiLineUpdateConnectorVertex: ValidationError: "config.mapping" is required': 'In de Multiline UpdateConnector actie heb je de configuratie niet volledig in gevuld.',
        'TimeOperationVertex: ValidationError: * with value * fails to match the required pattern: *': 'In de Tijd berekening actie heb je een ongeldige waarde ingevoerd. Controleer de invoer van de velden.',
        '*: ValidationError: "config.mapping" must be of type object': 'Het veld "Mapping" is niet gevuld in een van de acties.',
        'GetConnectorAdvanced*: ValidationError: "config.getConnectorFilters[1].seperator" must be one of [,, ;]': 'In de GetConnector actie heb je meerdere filters opgegeven. Je bent vergeten om en/of te selecteren.',
        'GetConnectorAdvanced*: ValidationError: "config.getConnectorOrderBy[*].orderByFieldId" is required': 'In de GetConnector actie heb je een lege "sortering" toegevoegd. Haal deze weg of vul hem volledig in.',
        'Invalid Token': 'Je AFAS token is niet geldig. Controleer het token en de omgeving, en dat de AppConnector geactiveerd is.'

    };
    if (meta) {
        for (const key in mapping) {
            let metaCopy = _.cloneDeep(meta);
            const parts = key.split('*');

            let hasMatch = true;
            for (const part of parts) {
                if (!metaCopy.includes(part)) {
                    hasMatch = false;
                    break;
                } else {
                    metaCopy = metaCopy.replace(part, '');
                }
            }

            if (hasMatch) {
                return mapping[key];
            }
        }
    }
}
