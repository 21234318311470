import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useStore } from '../store';
import Login from '../views/Login.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/email-verified',
    name: 'EmailVerified',
    component: () => import(/* webpackChunkName: "email_verified" */ '../views/EmailVerified.vue') 
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import(/* webpackChunkName: "overview" */ '../views/Overview.vue') 
  },
  {
    path: '/docs/:param?',
    name: 'Docs',
    props: true,
    component: () => import(/* webpackChunkName: "wiki" */ '../views/Docs.vue') 
  },
  {
    path: '/editor/new',
    name: 'New',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */ '../views/New.vue') 
  },
  {
    path: '/editor/:uuid',
    name: 'Editor',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */ '../views/Editor.vue') 
  },
  {
    path: '/admin/:uuid?',
    name: 'Admin',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue') 
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Check login before opening each route
router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith('/admin') && to.params.uuid && typeof to.params.uuid === 'string') {
    next();
    return;
  }

  if (to.path.startsWith('/email-verified')) {
    next();
    return;
  }

  if (to.path !== '/'){
    try {
      await useStore().dispatch('checkLogin');
      next();
    } catch (_){
      window.location.href = '/';
    }
    return;
  }
  
  // If a user is already logged in, redirect from login screen to overview, unless there is a code for the login screen
  if (to.query.code && typeof to.query.code === 'string') {
    next();
  } else {
    try {
      await useStore().dispatch('checkLogin');
      window.location.href = '/overview';
    } catch (_){
      next();
    }
  }
});
export default router;
